#menu-toggle:checked+#menu {
  display: block;
}

.navicon {
  width: 1.125em;
  height: .125em;
}

.navicon::before,
.navicon::after {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  transition: all .2s ease-out;
  content: '';
  background: #ffff;
}

.navicon::before {
  top: 5px;
}

.navicon::after {
  top: -5px;
}

.menu-btn:not(:checked)~.menu {
  display: none;
}

.menu-btn:checked~.menu {
  display: block;
}

.menu-btn:checked~.menu-icon .navicon {
  background: transparent;
}

.menu-btn:checked~.menu-icon .navicon::before {
  transform: rotate(-45deg);
}

.menu-btn:checked~.menu-icon .navicon::after {
  transform: rotate(45deg);
}

.menu-btn:checked~.menu-icon .navicon::before,
.menu-btn:checked~.menu-icon .navicon::after {
  top: 0;
}

.close-btn {
  background-color: white;
  border-radius: 40px;
  margin-bottom: 6px;
}

.filterCssOp {
  filter: contrast(40%) brightness(80%)
}

.filterCssFull {
  filter: contrast(500%) brightness(550%)
}

.transition-transform {
  transition-timing-function: ease-in-out;
  height: 100%;
}

.transform-o {
  transition-timing-function: cubic-bezier(0.45, 0.05, 0.55, 0.95);
}

.bg-text {
  font-size: 1.5rem;
  font-weight: 600;
  color: #1C042F;
}

.loader-icon {
  width: 300px;
  height: 300px;
  position: absolute;
  top: -2px;

}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100vh;
  background-color: #0d0017;
  z-index: 100;
  padding-top: 150px;

}

.loader .text-data {
  color: rgb(220, 220, 220);
  z-index: 200;
  padding-top: 25px;
  font-weight: 600;

}

.loader-img {
  position: relative;

}

.loader img {
  z-index: 200;
  opacity: 0.2
}



.testimonial-card video {
  width: 100%;
  height: 250px;
  border-radius: 40px;
  display: block;
  padding: 10px;
}