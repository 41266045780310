.home {
    position: absolute;
    top: 0px;
    background-color: #EDFCFB;

}

.banner {
    position: relative;
}

.bg-body {
    position: absolute;
    bottom: 100px;
}

.bg-body h1 {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1;
    padding-bottom: 10px;
    margin-bottom: 20px;
    background: -webkit-linear-gradient(#ffffff, #B7B7BC);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.bg-body p {
    margin-bottom: 20px;
}

.video-container {
    position: relative;
}

/* .video-container video {
    filter: contrast(116%);
} */
.black-img{
    background-image: url(../images/bg.png);
    background-size: cover;

    width: 100%;

}
.black-overlay {
    
    background-image: linear-gradient(248deg, #00043345, #000000);
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 100%;
}
.home  .black-overlay {
    
    background-image: none !important;
}
.scroll-icon-set {
    position: absolute;
    bottom: 10px;
    right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.scroll-icon {
    width: 55px;
    height: 55px;


}

.vertical-line {
    height: 50px;
    width: 2px;
    background-color: #E453A6;
    margin: 0 auto;
}

.textVertical {
    writing-mode: vertical-rl;
    text-orientation: mixed;
    color: white;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;


}

.vr-service .service-bg {
    left: 260px;
    top: -50px
}

.vr-service .service-circle {
    left: 238px;

}

.small-text {
    font-size: 16px;
    font-weight: 700;
    color: #072C2A;
}

.vr-service {
    background-image: url(../images/vr-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    height: 100%;
}

.services-imgs {
    width: 100%;
    overflow: hidden;
}

.vr-service .services-imgs img {
    height: auto;
    width: 57%;
}

.services h3 {
    font-size: 3rem;
    font-weight: 700;
    color: #1C042F;
}

.vr-service .text-label {
    font-size: 17px;
    text-align: center;
    color: #1C042F;
    display: flex;
    justify-content: center;

}


.card-service {
    display: flex;
    flex-direction: column;
    gap: 24px;

    padding: 40px;

}


.border-right {
    border-right: 1px solid #B597CC;

}



/* .card-service:nth-child(1) {
    border-right: 1px solid #B597CC;
} */
/* .vr-service .slick-slide:nth-child(1) .card-service {
    
} */


.card-service .card-head {
    font-size: 32px;
    font-weight: 700;
    line-height: 1.2;
    background: -webkit-linear-gradient(#1C042F, #072C2A);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.card-service .card-sub-head {
    font-size: 20px;
    font-weight: 700;
    color: #5F5667;

}

.all-service {
    background: linear-gradient(180deg, #F6ECFD 0%, #EDFCFB 100%);
    padding-top: 90px;
    padding-bottom: 90px;

}

.all-service .small-text {
    color: #A063CF;
}

.real-estate-service .services-imgs img {
    height: auto;
    width: 65%;
}

.real-estate-service {
    background-image: url(../images/realEstate-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;

    color: white;
}

.real-estate-service small {
    color: white;

}

.real-estate-service p {
    color: #D2D0DD;

}

.real-estate-head {
    font-size: 40px;
    line-height: 1.2;
    font-weight: 700;
    background: -webkit-linear-gradient(#ffffff, #ffffff9f);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.real-estate-head .small-text {
    color: #B597CC
}

.real-estate-service .service-bg {
    right: -35px;
}

.service-head {
    font-size: 44px;
    font-weight: 700;
    line-height: 1.2;
    color: #1C042F;
}

.hospitality-service {
    background: linear-gradient(201.09deg, #FDF4F9 -12.62%, rgba(255, 255, 255, 0) 92.48%),
        linear-gradient(113.66deg, #22DDD2 -19.33%, rgba(221, 185, 248, 0.01) 30.63%, rgba(140, 21, 233, 0) 54.68%),
        linear-gradient(0deg, #F6CAE3, #F6CAE3);


}

.hospitality-service .service-bg {
    left: 189px;
}

.hospitality-service .services-imgs img {
    height: auto;
    width: 58%;
}

.Visual-Experience-service {
    background: linear-gradient(174.61deg, #FDEDF6 -10.1%, #EDFCFB 85.37%);
}

.Visual-Experience-service .services-imgs img:nth-child(1) {
    height: auto;
    width: 68%;
}

.Visual-Experience-service .service-bg {
    width: 40%;
    right: 45px;
}

.experience {
    position: relative;
    /* padding-bottom: 20px; */
    margin-bottom: 70px;
}

.experience-img {
    z-index: 2;
    position: relative;
}

.explore-head {
    font-size: 18px;
    font-weight: 700;
    margin: 13px 0;
    color: #1C042F;
    text-align: start;
}

.experience-card {
    background-color: #E6DCEF;
    position: absolute;
    width: 100%;
    bottom: -64px;
    padding-top: 100px;
    padding-bottom: 10px;
    z-index: 1;
    border-radius: 0px 0px 26px 26px;
}

.dark-bg {
    background-color: #1A1320;
}

.OurClients {
    font-size: 32px;
    font-weight: 700;
    padding-bottom: 10px;
    color: white;
    text-align: center;
}

.img-testimonial {
    padding: 20px;
}

.img-testimonial button {
    position: relative;
    padding: 0px !important;
}

.img-testimonial img {
    border-radius: 20px;
}

.play {
    position: absolute;
    bottom: 20px;
    right: 30px;
}

.color-text {
    color: #A063CF;
}

.color-white {
    color: white;
}

.client-logo {
    width: 110px;
}

.slick-arrow {
    display: block;
    z-index: 100;
   

}

.slick-prev:hover {
    transform: scale(1.2);
    animation-timing-function:ease-in-out;
     /* Increase the size by 20% */
  }
.slick-prev{
   left:  -2px !important;
   height: 20px;
   transition: transform 0.3s ease-in-out;
}
.slick-next{
    right: 20px !important;
    height: 20px;
    transition: transform 0.3s ease-in-out;

}
.slick-prev:hover {
    transform: scale(0.9); 
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

  }

  .slick-next:hover{
    transform: scale(0.9); 
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.slick-prev:before, .slick-next:before{
    font-size: 40px !important;
    color: #525cc7 !important;
    background-color: rgb(255, 255, 255);
    padding: 5px 1px 0px;
    border-radius: 37px;
}



.slick-slider {
    width: 100% !important;
    overflow: hidden;
    z-index: 99;

}
.slick-list {
    
    margin: 10px !important;
    padding: 20px;


  }
  
/* .slick-slide div:first-of-type {
    border-right: 1px solid #B597CC;

  } */

/* service page  */
.services-grid {
    grid-template-columns: 80px 2fr;
    margin: 10px;
    background-color: white;
    border-radius: 10px;
}

.services-grid .accordion-header {
    margin: 25px auto;


}

.services-grid .accordion-header .icon {
    font-size: 30px;
    font-weight: 700;
    color: #717171;
}

.services-grid .sub-head {
    font-size: 20px;
    font-weight: 700;
    padding: 25px 0px 0px 10px;
    color: #1C042F;
    display: inline-block;
    margin-bottom: 4px;
    line-height: 1.6;

}

.services-grid .sub-head::after {
    content: '';
    display: block;
    border-bottom: 3px solid #22ddd2;
    ;
    border-radius: 20px;
    width: 50%;
    margin-top: 1px;
    color: #22ddd2;
}

.services-accordion-head {
    padding: 25px 25px 30px 0;
    cursor: pointer;
}

.services-accordion-head:hover h2 {
    color: #ac0163;
}

.accordion-body {
    padding: 0px 25px 25px 0px !important;

}

.services-vr {
    background: linear-gradient(180deg, #F6ECFD 0%, #EDFCFB 100%);
}

.service-page {
    position: absolute;
    top: 0px;
}


.service-page .sub-banner-video video {
    object-position: left center !important;
}

.service-page .bg-body {
    position: absolute;
}

.service-page .bg-body h1 {
    font-size: 3.5rem;
}

.service-page h2 {
    font-size: 2.2rem;
    font-weight: 700;
    color: #1C042F;
}

.service-page h4 {
    font-size: 1.6rem;
    font-weight: 500;
    color: #717171;
}

.service-page .part {
    margin-bottom: 2.75rem;
    padding-right: 2.25rem;


}
.part .sub-head{
    margin-bottom: 20px !important;
}


.setp-step {
    margin: 10px;
}

.setp-step h4 {
    font-size: 20px;
    font-weight: 600;
    color: #1C042F;
}

.setp-step p {
    margin-top: 6px;

}

.step-box {
    background: linear-gradient(0deg, rgba(172, 0, 98, 1) 0%, rgba(228, 83, 166, 1) 100%);
    color: rgb(255, 255, 255);
    padding: 8px 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.step-box small {
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;

}

.rotate {
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.accordion-fq .accordion-item {
    border: 1px solid rgba(235, 235, 235, 0.8);
    background-color: white;
    border-radius: 6px;
    margin: 10px;
}

.accordion-fq .accordion-header-fq {
    padding: 20px;
    justify-content: space-between;

}

.accordion-fq .accordion-content {
    justify-content: space-between;
    padding: 10px 20px 20px 20px;
}

.accordion-fq .accordion-active {
    border-bottom: 1px solid rgba(235, 235, 235, 0.8);

}


.simple-card {

    border: 1px solid rgba(235, 235, 235, 0.8);
    padding: 20px;
    border-radius: 10px;
    margin: 0px 6px 20px 6px;
    background-color: white;

}

.card-img {
    width: 55px;
}

.card-head {
    font-size: 18px;
    font-weight: 700;
}

.step {
    margin-bottom: 20px;
}

/* project page  */
.project-page {
    position: absolute;
    top: 0px;
}

.sub-banner-video video {
    height: 55vh;
    width: 100%;
    object-fit: cover;
    object-position: left top;
}


.project .bg-body {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.project .bg-body h1 {
    font-size: 3.5rem;
}

.tab-cus-body {
    padding: 20px;
    gap: 10px;
}

.tab-container {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.active-tab::after {
    content: '';
    display: block;
    border-bottom: 5px solid #E453A6;
    border-radius: 20px;
    width: 100%;
    margin-top: 1px;
    color: #22ddd2;
}

.sub-tab {
    font-size: 18px;
    font-weight: 500;
    padding-bottom: 6px;
}

.tab-container span:hover {
    color: var(---color-text-dark);
}

/* contact page  */


.contact-page {
    position: absolute;
    top: 0px;
    background: linear-gradient(180deg, #F6ECFD 0%, #EDFCFB 100%);
}



.sub-banner-video video {
    object-position: left center !important;


}

.contact-page .bg-body {
    position: absolute;

}

.contact-page .bg-body h1 {
    font-size: 3.5rem;
    font-weight: 700;
}

.contact-page h2 {
    font-size: 2.2rem;
    font-weight: 700;
    color: #1C042F;

}

.contact-page h4 {
    font-size: 1.6rem;
    font-weight: 500;
    color: #717171;
}

.contact-page h1 {
    font-size: 3rem;
    font-weight: 500;
    color: #1C042F;

}

.contact-body p {
    width: 80%;

}

.info {
    padding: 21px;
    border: 1px solid rgba(235, 235, 235, 0.8);
    border-radius: 6px;
    margin: 20px 0 10px 0px;
    width: 80%;

}

.contact-page h1 span {
    font-size: 3rem;
    font-weight: 800;
}

.info h6 {

    font-size: 18px;
    font-weight: 500;
}

.card-contact {
    background-color: white;
    border-radius: 20px;
    padding: 25px;
}

.card-contact h4 {
    color: #2a2a2a;
}

.card-contact h4::after {
    content: '';
    display: block;
    border-bottom: 3px solid #22ddd2;
    border-radius: 20px;
    width: 50%;
    margin-top: 1px;
    color: #22ddd2;
}

.icon-sm {
    width: 20px;
}

.cta-banner {
    background-image: url(../images/ad-vr.png);
    background-size: cover;
    padding: 35px;
    color: white;
    border-radius: 15px;
}

.cta-banner h5 {
    font-size: 32px;
    width: 80%;
    font-weight: 600;
    padding-bottom: 20px;
    line-height: 1.2;

}

.cta-banner .text-style {
    font-weight: 800;
    color: var(---primary-color);
}



.ani-banner {
    background-image: url(../images/ad-an.png);
    background-size: cover;
    padding: 35px;
    color: rgb(255, 255, 255);
    border-radius: 15px;
}

.ani-banner h5 {
    font-size: 36px;
    text-align: center;
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 1.2;
}


.Vs-banner {
    background-image: url(../images/ad-vs.png);
    background-size: cover;
    padding: 35px;
    color: rgb(255, 255, 255);
    border-radius: 15px;
}

.Vs-banner h5 {
    font-size: 36px;
    text-align: center;
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 1.2;

}

.ph-banner {
    background-image: url(../images/ad-ph.png);
    background-size: cover;
    padding: 35px;
    color: rgb(255, 255, 255);
    border-radius: 15px;
}

.ph-banner h5 {
    font-size: 36px;
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-weight: 700;
    padding-bottom: 20px;
    line-height: 1.2;
}

.client {
    width: 90%;
    margin: auto;
}

.client-icon {
    width: 60%;
    margin: 10px auto;
}

.wapp {
    z-index: 1000;
    width: 80px;
    position: fixed;
    padding: -10px;
    border-radius: 50px;
    /* background-color: #eeeeee; */
    cursor: pointer;
    right: 70px;
    bottom: 20px;
    /* box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
    animation-name: wapp;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-iteration-count: 1;
}

@keyframes wapp {
    0% {
        background-color: rgb(255, 255, 255);
        bottom: 0px;
    }

    50% {
        bottom: 50px;
    }

    100% {
        bottom: 20px;
    }

}


.video-background {
    background: #000;

  }
  
  