@tailwind base;
@tailwind components;
@tailwind utilities;








.btn {
    display: inline-block !important;
    font-family: "Inter", sans-serif;
    font-weight: 500;
    line-height: 1.5;
    text-align: center;
    text-decoration: none;
    vertical-align: middle;
    cursor: pointer;
    -moz-user-select: none;
    user-select: none;
    border: 1px solid transparent;
    padding: 0.375rem 1.3rem;
    font-size: 16px;
    border-radius: 0.25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}



.btn-primary {
    color: #fff;
    cursor: pointer;
    display: block;
    position: relative;
    color: var(---color-text-dark);
    background-color: var(---primary-color);
    border: 2px solid var(---primary-color);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1)
}

.btn-primary:hover {
    color: #000 !important;
    background-color: transparent;
    border: 2px solid #e7e7e7;
    z-index: 0;

}

.btn-primary:hover:before {
    left: 0%;
    right: auto;
    width: 100%;
}

.btn-primary:before {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 0px;
    z-index: -1;
    content: '';
    color: #000 !important;
    background: #fafafa !important;
    border: 2px solid #fafafa;
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}





.btn-secondary {
    color: #fff;
    cursor: pointer;
    display: block;
    position: relative;
    color: var(---color-text-base);
    background-color: var(---secondary-color);
    border: 2px solid var(---secondary-color);
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1)
}

.btn-secondary:hover {
    color: #000 !important;
    background-color: transparent;
    border: 2px solid #eaeaea;
    z-index: 0;

}

.btn-secondary:hover:before {
    left: 0%;
    right: auto;
    width: 100%;
}

.btn-secondary:before {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 0px;
    z-index: -1;
    content: '';
    color: #000 !important;
    background: #fafafa !important;
    border: 2px solid #fafafa;
    transition: all 0.4s cubic-bezier(0.42, 0, 0.58, 1);
}
#wrapper{
    z-index: 100;
}

.experience-card .btn-secondary {
    height: 35px;
    width: 35px;
    display: flex;
}

.experience-card .btn-secondary:before {
    display: none !important;
}

.experience-card .btn-secondary:hover {
    background-color: #2d024f;
}






/* 

.btn-primary {
    color: var(---color-text-dark);
    background-color: var(---primary-color);
    border-color: var(---primary-color);
}

.btn-primary:hover {
    color: var(---color-text-dark);
    background-color: var(---primary-color-hov);
    border-color: var(---primary-color-hov);
} */


/* .btn-secondary {
    color: var(---color-text-base);
    background-color: var(---secondary-color);
    border-color: var(---secondary-color);
}

.btn-secondary:hover {
    color: var(---color-text-base);
    background-color: var(---secondary-color-hov);
    border-color: var(---secondary-color-hov);
} */


@media (max-width: 1399.98px) {
    .hide-md {
        display: none !important;

    }

   
}
@media (max-width: 1199.98px) {
    .hide-md {
        display: none;

    }

    .p-t-b {
        padding-top: 60px;
        padding-bottom: 60px;
    }

}

@media (max-width: 991.98px) {
    .hide-md {
        display: none;

    }
    .hospitality-service .service-bg {
        left: 104px !important;
    }
    .vr-service .service-circle {
        left: 136px !important;
    }
    .vr-service .service-bg {
        left: 172px !important;
        top: -22px !important;
    }
 
    
}






@media (max-width: 575.98px) {
    .bg-body h1 {
        font-size: 3.1rem !important;
        line-height: 1 !important;
    }

    .border-right {
        border: none !important;
    }

    .card-service {
        padding: 30px !important;
    }

    .hide-md {
        display: block;

    }
  
}

@media (max-width: 767.98px) {

    .p-t-b {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .w-20 {
        width: 3rem !important;
    }

    .gap-4 {
        gap: 8px !important;
    }

    .nav {
        padding: 20px 10px;
        

    }
    .all-service {
        padding-top: 45px !important;
        padding-bottom: 45px !important;
     
    }

    .banner .bg-body {
        bottom: 100px !important;
        width: 95%;
    }


    .service-page .bg-body {
        bottom: 0px !important;
    }

    .service-page .bg-body h1 {
        font-size: 1.5rem !important;
    }

    .project-page .bg-body {
        bottom: 0px !important;
    }

    .project-page .bg-body h1 {
        font-size: 1.5rem !important;
    }

    .contact-page .bg-body {
        bottom: 0px !important;
    }

    .contact-page .bg-body h1 {
        font-size: 1.5rem !important;
    }

    .nav-sm {
        flex-direction: row-reverse;
        height: 100vh;
        position: fixed;
        width: 100%;
        background: rgba(0, 0, 0, 0.7) !important;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(0, 0, 0, 0.73);

    }

    .nav-sm ul li {
        color: #eaeaea;
        border-bottom: none;
        text-align: center;
        font-size: 18px;
    }

    .nav-sm ul li a {
        font-size: 20px;
    }

    .nav-sm .nav-menu {
        display: inline-block;
        position: absolute;
        top: 8px;
        right: 13px;
    }

    .nav img {
        width: 150px;
    }

    .vr-service .services-imgs img:nth-child(2) {
        margin-left: 12%;
    }

    .vr-service .services-imgs img {

        width: 45% !important;
        height: auto !important;
    }

    .vr-service .service-bg {
        left: 147px !important;
        z-index: -1;
        top: -11px !important;
    }

    .vr-service .service-circle {
        left: 147px !important;
    }

    .vr-service .text-label {
        font-size: 14px !important;
    }

    .icon-vr {
        width: 30px;
    }

    .hospitality-service .services-imgs img {
        width: 50% !important;

    }

    .hospitality-service .services-imgs img:nth-child(1) {

        margin-left: 14% !important;
    }

    .hospitality-service .service-bg {
        left: 138px !important;
    }

    .real-estate-service .services-imgs img {
        width: 60% !important;

    }

    .real-estate-service .service-bg {
        right: 4px !important;
    }


    .Visual-Experience-service .services-imgs img:nth-child(1) {
        width: 60% !important;

    }




    .real-estate-head {
        font-size: 30px !important;

    }

    .services h3 {
        font-size: 30px !important;

    }

    .service-head {
        font-size: 34px !important;
        line-height: 1 !important;
    }

    .card-service .card-head {
        font-size: 27px !important;
    }

    .OurClients {
        font-size: 30px !important;

    }

    .hide-sm {
        display: none !important;
    }

    .hide-md {
        display: block !important;

    }

    .blod-text {
        font-size: 20px !important;
        padding-left: 6px !important;
    }

    .nav ul li .active::after {
        display: none !important;
    }

    .nav-menu {
        background: rgba(255, 255, 255, 0.49);
        border-radius: 12px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
        padding: 3px;
    }

    .sub-banner-video video {
        height: 25vh !important;
    }

    .scroll-icon-set {
        right: 0px !important;
        bottom: 80px !important;
    }

    .scroll-icon {
        width: 40px !important;
        height: 40px !important;
    }

    .services-grid {
        margin: 0px 0px 10px 0 !important;
        grid-template-columns: 10px 2fr !important;

    }

    .services-grid .accordion-header .icon {
        display: none;
    }

    .service-page h2 {
        font-size: 1.8rem !important;
    }

    .service-page h4 {
        font-size: 1rem !important;
    }

    .service-page p {
        padding-right: 10px !important;
    }

    .services-accordion-head {
        padding: 10px 10px 10px 0 !important;


    }

    .services-grid .part {
        padding-right: 6px !important;
        margin-bottom: 10px !important;
    }

    .simple-card {
        margin-left: 0px !important;
        margin-right: 10px !important;
        padding: 12px;
    }

    .simple-card p {
        padding: 0px !important;
    }

    .experience-card .btn-secondary {
        height: 40px;
        width: 40px;
        display: flex;
    }

    .accordion-fq .accordion-item {
        margin: 10px 0px !important;
    }

    .part {
        flex-wrap: wrap !important;
        text-align: center !important;
        justify-content: center;
    
    }

    .services-grid .sub-head::after {
        margin: 5px auto !important;


    }
  
    .img-testimonial {
        padding: 0px !important;
    }

 

    .experience-card {
        bottom: -55px !important;
        padding-top: 50px !important;
        padding-bottom: 0px;
    }

    .explore-head {
        margin: 7px 0px !important;
        font-size: 16px !important;
        line-height: 1.2 !important;
        text-align: start;

    }

    .accordion-fq .accordion-header-fq {
        padding: 16px !important;
        text-align: start !important;

    }

    .accordion-content {
        text-align: start;
        padding: 10px 16px 16px 16px !important;
    }

    .cta-banner h5 {
        width: 100% !important;
    }

    .cta-banner h5 {
        font-size: 24px !important;
    }

    .ani-banner h5 {
        font-size: 24px !important;

    }

    .Vs-banner h5 {
        font-size: 24px !important;

    }

    .ph-banner h5 {
        font-size: 24px !important;
        margin: 0px !important;
        width: 100% !important;

    }

    .contact-page h1 {
        font-size: 2rem !important
    }

    .contact-body p {
        width: 100% !important;
    }

    .info {
        width: 100% !important;

    }

    .card-contact {
        padding: 20px;
    }

    .footer-head {
        padding: 0px 20px;
    }

    .footer .dark-bg {
        padding-bottom: 10px !important;
        padding-top: 20px !important;
    }

    .client-icon {
        width: 60%;
        margin: auto;
    }

    .footer li {
        margin: 4px 0px !important;
    }

    .footer-head {
        padding: 0px 0px !important;
    }






}
