@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        ---color-text-base: #ffffff;
        ---color-text-dark: #131313;
        ---primary-color: #22ddd2;
        ---primary-color-hov: #2df8eb;

        ---secondary-color: #38085E;
        ---secondary-color-hov: #200339;

    }
}

@font-face {
    font-family: 'Segoe';
    src: url('/src/font/segoeuii.ttf');
    font-weight: 300;
}

@font-face {
    font-family: 'Segoe';
    src: url('/src/font/segoeuil.ttf');
    font-weight: 400;
}

@font-face {
    font-family: 'Segoe';
    src: url('/src/font/segoeui.ttf');
    font-weight: 500;
}

@font-face {
    font-family: 'Segoe';
    src: url('/src/font/seguisb.ttf');
    font-weight: 600;
}

@font-face {
    font-family: 'Segoe';
    src: url('/src/font/segoeuib.ttf');
    font-weight: 700;
}

@font-face {
    font-family: 'Segoe';
    src: url('/src/font/seguibl.ttf');
    font-weight: 800;
}


body {
    font-family: 'Segoe';
    overflow-x: hidden;

}

label {
    font-size: 18px;
    font-weight: 500;
    color: #4d4d4d;

}

input {
    border-color: #cacaca;
}

textarea {
    border-color: #cacaca;
}


p {
    font-family: 'Segoe';
    font-size: 16px;
    font-weight: 500;
    color: #0A042F;
}

.nav {
    background: linear-gradient(355deg, rgba(252, 252, 252, 0) 0%, rgba(30, 0, 54, 0.5522584033613445) 100%);

}

.nav ul a {
    font-size: 18px;
    font-weight: 500;
}

.nav ul a:hover {
    color: var(---primary-color);
}

.nav ul li:hover {
    color: var(---primary-color);

}

.nav ul li {
    padding: 18px;
}

nav ul li a:hover {
    color: var(---primary-color) !important;
}

.nav ul li .active::after {
    content: '';
    display: block;
    border-bottom: 4px solid #E453A6;
    border-radius: 20px;
    width: 100%;
    margin-top: 1px;
    color: #22ddd2;
}

.gap-8 {
    gap: 2rem
}

.gap-8 {
    gap: 1.75rem;
}

.p-t-b {
    padding-top: 90px;
    padding-bottom: 90px;
}

.footer {
    color: white;
}

.blod-text {
    font-size: 30px;
    font-weight: 700;

}

.footer p {
    color: #9A979D
}

.footer li {
    margin: 4px 20px;
}

.footer li a {
    font-size: 16px;
}

.footer .dark-bg {
    padding-top: 40px;
    padding-bottom: 20px;
}

.footer .logo {
    width: 40%;
    margin-bottom: 12px;

}

.copy {
    border-top: 1px solid #ffffff31;
    padding: 10px 0 0 0;
    text-align: center;
    margin-top: 35px
}

.copy small {
    font-size: 14px;
    color: #ffffffb4;

}

.tab-cus {
    border-radius: 2px;
    font-size: 14px;
    font-weight: 600;
}

.modal-body {
    background-color: white;
    padding: 30px;
    border-radius: 25px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

.modal-body .close-btn {
    margin-bottom: 0px;
    padding: 0px;
    float: inline-end;

}

.modal-body .card-contact {
    background-color: white;
    padding: 0px;
}

.width-max {
    max-width: 100% !important;
}


.video-foreground{
    height: 100%;
    width: 100%;
}


 @media (max-width: 575.98px) { 


  }
 
 @media (max-width: 767.98px) { 

 }
 
 @media (max-width: 991.98px) { 

  }
 
 @media (max-width: 1199.98px) { 
 
    
  }
 
 @media (max-width: 2000.98px) { 
    .video-foreground{
        height: 100vh;
        width: 100%;

        
    } 

  }
 